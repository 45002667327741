import Page from './page';

const BackdropAlert = ({ children }) => {
  return (
    <div
      className={
        'fixed top-0 right-0 left-0 bottom-0 bg-[#262626c9] flex items-center justify-center'
      }
    >
      <Page>{children}</Page>
    </div>
  );
};

export default BackdropAlert;
