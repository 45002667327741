import Page from './page';
import Profile from './profile';
import ProfileSelector from './profile-selector';
import { useState } from 'react';
import { HouseFill } from 'react-bootstrap-icons';

const Home = () => {
  const [profile, setProfile] = useState(null);
  const [completed, setCompleted] = useState(null);

  return (
    <div className="flex flex-col gap-[30px] p-[1.5em] justify-start items-center h-full w-full min-h-[100vh] bg-[#01222C]">
      <img className={'h-[70px]'} src={'cf-logo.png'} alt={'Cloudfest Logo'} />
      <Page>
        {completed ? (
          <div className="flex items-center justify-center flex-col gap-[20px] text-center">
            <img
              className={'h-[50px]'}
              src={'check.png'}
              alt={'Cloudfest Logo'}
            />
            <div>Everything is looking great</div>
            <div>We received your request and will review it shortly</div>
            <div>
              Your profile or offer will then be printed and displayed on the
              Job Board and posted in the Mattermost channel #Jobs
            </div>
            <div />
            <div>
              All submitted data are scheduled to be removed from this system on{' '}
              <strong>March 27th 2023</strong> but you can request that we
              delete them at any time by posting a message in the Mattermost
              channel <strong>#Jobs</strong>
            </div>
            <div className="text-[10px]">Your request ID : {completed}</div>
            <div
              onClick={() => {
                setCompleted(null);
                setProfile(null);
              }}
              className="flex drop-shadow-md cursor-pointer border rounded-[12px] py-[10px] px-[15px] items-center mb-[15px] gap-[10px]"
            >
              <HouseFill />
              <div>Menu</div>
            </div>
          </div>
        ) : (
          <div>
            {profile ? (
              <>
                <div
                  onClick={() => {
                    setCompleted(null);
                    setProfile(null);
                  }}
                  className="flex drop-shadow-md cursor-pointer border rounded-[12px] py-[10px] px-[15px] items-center mb-[15px] gap-[10px]"
                >
                  <HouseFill />
                  <div>Menu</div>
                </div>
                <Profile
                  profile={profile}
                  onCompleted={(req) => setCompleted(req)}
                />
              </>
            ) : (
              <ProfileSelector onProfileSelected={setProfile} />
            )}
          </div>
        )}
      </Page>
    </div>
  );
};

export default Home;
