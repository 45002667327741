import Page from './page';
import { useState, useEffect } from 'react';
import apiUrl from './api';
import { BallTriangle } from 'react-loading-icons';
import FileSaver from 'file-saver';
import VCard from 'vcard-creator';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

const DisplayCandidate = ({ id }) => {
  const [candidate, setCandidate] = useState(null);
  const [status, setStatus] = useState(null);
  useEffect(() => {
    const fetchSkills = async () => {
      const response = await fetch(`${apiUrl}/api/display`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ type: 'candidate', id }),
      });
      if (response.ok) {
        const data = await response.json();
        setCandidate(data.data);
        setStatus(true);
      } else {
        setStatus(false);
      }
    };

    fetchSkills();
  }, [id]);

  if (!status) {
    return (
      <div className="flex flex-col gap-[30px] p-[1.5em] justify-start items-center h-full w-full min-h-[100vh] bg-[#01222C]">
        <img
          className={'h-[70px]'}
          src={'cf-logo.png'}
          alt={'Cloudfest Logo'}
        />
        {status === null ? (
          <BallTriangle />
        ) : (
          <div className="bg-white w-[500px] h-[50px] flex items-center justify-center rounded-[10px] p-[20px]">
            We could not find the content you are looking for, sorry
          </div>
        )}
      </div>
    );
  }

  const onSave = () => {
    const vcard = new VCard();
    vcard.addName(candidate.lastName, candidate.firstName);
    vcard.addJobtitle(candidate.title);
    vcard.addURL(candidate.url);
    vcard.addNote(candidate.freetext);
    vcard.addEmail(candidate.email);

    var file = new Blob([vcard.toString()], {
      type: 'text/vcard;charset=utf-8',
    });
    FileSaver.saveAs(
      file,
      `${candidate.firstName}${candidate.lastName}.vcf`,
      true
    );
  };

  return (
    <div className="flex flex-col gap-[30px] p-[1.5em] justify-start items-center h-full w-full min-h-[100vh] bg-[#01222C]">
      <img className={'h-[70px]'} src={'cf-logo.png'} alt={'Cloudfest Logo'} />
      <Page>
        <div className="flex gap-[10px] flex-col">
          {candidate.profilePicture && (
            <img
              className={'rounded-full h-[150px] w-[150px] m-auto'}
              src={`data:image/jpeg;base64,${candidate.profilePicture}`}
              alt="Profile"
            />
          )}
          <div className="text-center text-[25px] font-[600]">
            {candidate.firstName} {candidate.lastName}
          </div>
          <div className="text-center">{candidate.title}</div>
          <div className="text-center">{candidate.location}</div>
          {candidate.url && (
            <a href={candidate.url} target="_blank" rel="noreferrer">
              <div
                className={`flex drop-shadow-md cursor-pointer border rounded-[12px] py-[10px] px-[15px] items-center gap-[10px]`}
                onClick={() => {}}
              >
                Link to online profile
                <div className="ml-auto">
                  <BoxArrowUpRight />
                </div>
              </div>
            </a>
          )}
          {candidate.freetext && (
            <div className="whitespace-pre-wrap bg-[#f7f7f7] p-[10px] rounded-[12px]">
              {candidate.freetext}
            </div>
          )}
          <div className="flex flex-col md:flex-row gap-[10px] bg-[#f7f7f7] p-[10px] rounded-[12px] items-center">
            <div>
              {candidate.profilePicture && (
                <img
                  className={
                    'rounded-full h-[50px] w-[50px] m-auto hidden md:block'
                  }
                  src={`data:image/jpeg;base64,${candidate.profilePicture}`}
                  alt="Profile"
                />
              )}
            </div>
            <div>
              <div className="font-[500]">
                {candidate.firstName} {candidate.lastName}
              </div>
              <div className="text-[12px]">{candidate.email}</div>
            </div>
            <div className="ml-auto mr-auto md:mr-0">
              <div
                className="bg-[#01222C] px-[15px] py-[5px] text-white rounded-[15px] cursor-pointer"
                onClick={onSave}
              >
                Save Contact
              </div>
            </div>
          </div>
        </div>
      </Page>
    </div>
  );
};

export default DisplayCandidate;
