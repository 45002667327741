import BigButton from './big-button';
import Separator from './separator';
import TextBox from './text-box';

const ProfileSelector = ({ onProfileSelected }) => {
  return (
    <div className="flex flex-col gap-[15px]">
      <TextBox>
        <div>
          <strong>Welcome Hacker</strong> to the Job Board !
        </div>
        <div>
          You can register your <strong>job offer</strong> here, or setup your{' '}
          <strong>profile</strong>
        </div>
        <div>Offers and profiles will be available during the event.</div>
        <div>
          All data will be cleared from this platform on{' '}
          <strong>March 27th 2023</strong>
        </div>
      </TextBox>
      <div className="text-center font-[600]">
        Please select a profile to use this application
      </div>
      <BigButton
        title={'Candidate'}
        onClick={() => onProfileSelected('candidate')}
      />
      <Separator />
      <BigButton
        title={'Recruiter'}
        onClick={() => onProfileSelected('employer')}
      />
    </div>
  );
};

export default ProfileSelector;
