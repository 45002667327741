const DangerButton = ({ title, onClick, disabled }) => {
  return (
    <div
      className={`cursor-pointer w-full ${
        disabled
          ? 'bg-[#f3f3f3] text-[#cbcbcb]'
          : 'bg-[#fff2f2] hover:bg-[#ffe6e6] text-[#FF0000] '
      } text-center py-[16px] rounded-[12px]`}
      onClick={() => !disabled && onClick()}
    >
      {title}
    </div>
  );
};
export default DangerButton;
