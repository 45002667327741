import './App.css';
import Home from './components/home';
import Moderation from './components/moderation';
import DisplayJob from './components/display-job';
import DisplayCandidate from './components/display-candidate';

const App = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  if (queryParameters.get('token')) {
    const token = queryParameters.get('token');
    return <Moderation token={token} />;
  }
  if (queryParameters.get('job')) {
    const id = queryParameters.get('job');
    return <DisplayJob type="job" id={id} />;
  }
  if (queryParameters.get('candidate')) {
    const id = queryParameters.get('candidate');
    return <DisplayCandidate type="candidate" id={id} />;
  }
  return <Home />;
};
export default App;
