import { XLg } from 'react-bootstrap-icons';
import {useState} from 'react';

const Page = ({ children }) => {
  const [showGDPR, setSHOWGDPR] = useState(false);
  return (
    <>
      <div
        className={
          'bg-white min-h-[50vh] min-w-[200px] md:min-w-[500px] md:max-w-[30vw] p-[20px] rounded-[8px]'
        }
      >
        {children}
      </div>
      {showGDPR && <div className="fixed top-[50px] right-[50px] left-[50px] bottom-[50px] bg-white rounded-[8px] p-[10px] text-[12px] overflow-y-auto border border-black border-[2px] shadow-md">
        <div className="flex justify-end font-[500] cursor-pointer">
          <div onClick={() => setSHOWGDPR(false)}><XLg height={20} width={20}/></div>
        </div>
        <div className="font-[600]">Cloudfest Hackathon Job Board Privacy Policy</div>
        <div>This website has been created to propose Cloudfest Hackathon Participants to participate in our Job Board</div>
        <div className="mb-[5px] mt-[5px] text-[12px] font-[600]">What data do we collect ?</div>
        <div>
          Cloudfest Hackathon Job board collects the following data :
          <ul>
            <li>- Personal identification information ( Name, Email address, Job Title, Location, Skills, Links to website or online profile, Personal description )</li>
            <li>- IP Address</li>
          </ul>
        </div>
        <div className="mb-[5px] mt-[5px] text-[12px] font-[600]">How do we collect your data ?</div>
        <div>
          You directly provide Cloudfest Hackathon Job board with most of the data we collect, we collect data and process data when you :
          <ul>
            <li>- Register online</li>
            <li>- Use or view our website</li>
          </ul>
        </div>
        <div className="mb-[5px] mt-[5px] text-[12px] font-[600]">How will we use your data ?</div>
        <div>
          Cloudfest Hackathon Job boardcollects your data so that we can :
          <ul>
            <li>- Create your profile on our online platform</li>
            <li>- Print a card containing your data for the job board</li>
            <li>- Allow participants to save your data with a vCard formatted file after scanning the card on the job board</li>
          </ul>
        </div>
        <div className="mb-[5px] mt-[5px] text-[12px] font-[600]">How do we store your data ?</div>
        <div>
          Cloudfest Hackathon Job board collects stores your data :
          <ul>
            <li>- In a datacenter located in Europe</li>
            <li>- We will keep your data for the time of the cloudfest 2023, once this time period has expired, we will delete your data by removing all entries from our files.</li>
          </ul>
        </div>
        <div className="mb-[5px] mt-[5px] text-[12px] font-[600]">What are your data protection rights ?</div>
        <div>
          <ul>
            <li>- <b>Access :</b> You have the right to access your data by requesting a copy of your personal data.</li>
            <li>- <b>Rectification :</b>You have the right to request that we correct any information that you believe is inacurrate or complete information you believe is incomplete.</li>
            <li>- <b>Erasure :</b>You have the right to request that we erase your personal data</li>
            <li>- <b>Restrict Processing :</b>You have the right to request that we restrict the processing of your personal data</li>
            <li>- <b>Object Processing :</b>You have the right to object that we process your personal data</li>
            <li>- <b>Portability :</b>You have the right to request that we transfer the data that we have collected to you or another organization</li>
          </ul>
          <div>
            All your data will be removed from this platform on March 27th 2023
            If you wish to request any of your right before that date, you can contact us at our email : <span className="font-[600]">carole@cloudfest.com</span> or by posting a message in the #Jobs channel in Cloufest Hackathon Mattermost
          </div>
          <div className="mb-[5px] mt-[5px] text-[12px] font-[600]">We don't use Cookie for this website</div>
          <div>This policy apply only to the Hackathon Job Board website <span className="font-[600]">https://hackathon-jobboard.cloudfest.com</span></div>
          <div className="mb-[5px] mt-[5px] text-[12px] font-[600]">Contact US</div>
          <div>
            <div>You can write us an email at : <span className="font-[600]">carole@cloudfest.com</span></div>
            <div>You can also use our #Jobs channel on Cloudfest Hackathon Mattermost</div>
            <div>Or write to us at : WHD Event GmbH, Breslauer Platz 4, 50668 Cologne, Germany</div>
          </div>
        </div>
      </div>}
      <div className="text-[10px] text-white flex flex-col text-center gap-[3px]">
        <div className="hover:underline cursor-pointer" onClick={() => setSHOWGDPR(true)} id="gdpr-link">GDPR - Privacy Policy of this service</div>
        <div>© 2023 – CloudFest | All rights reserved</div>
        <div>Created for Cloudfest Hackathon by <a href="https://github.com/matthieutirelli" target="_blank" className="hover:underline">Matthieu TIRELLI</a></div>
        <div className="border-b-[1px] border-dotted my-[10px]"></div>
        <div className="flex gap-[10px] justify-center">
          <a target="_blank" className="hover:underline" href="https://www.cloudfest.com/imprint">Imprint</a>
          <a target="_blank" className="hover:underline" href="https://www.cloudfest.com/privacy-policy">Privacy Policy</a>
        </div>
        <div className="flex gap-[10px] justify-center">
          <a target="_blank" className="hover:underline" href="https://www.cloudfest.com/terms-and-conditions">Terms and Conditions</a>
          <a target="_blank" className="hover:underline" href="https://www.cloudfest.com/code-of-conduct">Code of conduct</a>
        </div>
      </div>
    </>
  );
};

export default Page;
