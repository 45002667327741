import {
  Building,
  PeopleFill,
  Terminal,
  ExclamationTriangle,
  CheckLg,
  X,
  SendFill,
  Printer,
  Trash
} from 'react-bootstrap-icons';
import { useState, useEffect, useCallback, useReducer } from 'react';
import apiUrl from './api';
import { BallTriangle, TailSpin } from 'react-loading-icons';
import { Input, InputArea, SkillsSelector } from './form-builder';

const MenuItem = ({
  Icon,
  title,
  setMenu,
  id,
  menu,
  count,
  color,
  bgActive,
}) => {
  const colorCode = () => {
    switch (color) {
      case 'info':
        return 'bg-transparent text-[#53aeff]';
      case 'success':
        return 'bg-[#baffba] text-[#88bc92]';
      case 'warning':
        return 'bg-[#ffdd93] text-[#ff9100]';
      default:
      case 'error':
        return 'bg-[#ffccce] text-[#FF0000]';
    }
  };

  return (
    <div
      className={`flex drop-shadow-md cursor-pointer border rounded-[12px] py-[10px] px-[15px] items-center gap-[10px] ${
        menu && menu === id ? 'bg-[#f3f3f3]' : ''
      } ${bgActive ? 'bg-[#07f2f2]' : ''}`}
      onClick={() => setMenu(id)}
    >
      {Icon && (
        <Icon
          height={'20px'}
          stroke={menu === 'save' && menu === id && '#000000'}
        />
      )}
      <div>{title}</div>
      {count && (
        <div
          className={`ml-auto ${colorCode()} w-[20px] text-[#FF0000] h-[20px] rounded-full flex items-center justify-center text-[12px]`}
        >
          {count}
        </div>
      )}
    </div>
  );
};

const ModerationMenu = ({ setMenu, menu, moderation }) => {
  return (
    <div className={'w-1/6 min-w-[180px] flex flex-col gap-[10px]'}>
      <MenuItem
        Icon={Building}
        id={'jobs'}
        title={'Jobs'}
        setMenu={setMenu}
        menu={menu}
        count={moderation?.jobs || null}
      />
      <MenuItem
        Icon={PeopleFill}
        id={'candidates'}
        title={'Candidates'}
        setMenu={setMenu}
        menu={menu}
        count={moderation?.candidates || null}
      />
    </div>
  );
};

const ModerationBadgeStatus = ({ status }) => {
  switch (status) {
    case true:
      return <CheckLg />;
    case false:
      return <X />;
    default:
      return <ExclamationTriangle />;
  }
};

const getModerationColor = (status) => {
  switch (status) {
    case true:
      return 'success';
    case false:
      return 'danger';
    default:
      return 'warning';
  }
};

const RenderJobCard = ({ item }) => {
  return (
    <div className="text-[12px]">
      <div className="font-[800]">{item.name}</div>
      <div>{item.jobTitle}</div>
      <div>{item.recruiterName}</div>
    </div>
  );
};

const RenderCandidateCard = ({ item }) => {
  return (
    <div className="text-[12px]">
      <div className="font-[800]">
        {item.firstName} {item.lastName}
      </div>
      <div>{item.email}</div>
      <div>{item.title}</div>
    </div>
  );
};

const renderItemReducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return {};
    case 'SET':
      return { ...state, [action.key]: action.value };
    default:
      return state;
  }
};

const MenuModerationStatus = ({ status, onApprove, onReject }) => {
  return (
    <div className="flex gap-[5px]">
      <MenuItem
        id={'approve'}
        key={'approve'}
        bgActive={status === true}
        title={'Approve'}
        setMenu={onApprove}
      />
      <MenuItem
        id={'reject'}
        key={'reject'}
        bgActive={status === false}
        title={'Reject'}
        setMenu={onReject}
      />
    </div>
  );
};

const RenderItemCandidate = ({ selected, item, token, onUpdate }) => {
  const [itemCopy, setItemCopy] = useState({});
  const [state, dispatch] = useReducer(renderItemReducer, {});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch({ type: 'RESET' });
    setItemCopy(item);
  }, [item]);

  const updateValue = useCallback(
    (key, value) => {
      dispatch({ type: 'SET', key, value });
    },
    [dispatch]
  );

  const edited =
    Object.keys(state).filter((k) => itemCopy[k] !== state[k]).length > 0;

  const saveAction = async () => {
    setSaving(true);
    const response = await fetch(`${apiUrl}/api/candidates?id=test`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ ...item, ...state, id: selected }),
    });

    if (response.ok) {
      await response.json();
      setSaving(false);
      setItemCopy({ ...item, ...state });
      onUpdate();
    } else {
      console.error('Cannot update');
    }
  };

  const onDelete = async () => {
    const confirmed = window.confirm("Are you sure you want to delete, this cannot be un-done ?")
    if(confirmed) {
      const response = await fetch(`${apiUrl}/api/candidates?id=test`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ ...item, ...state, id: selected, delete: true }),
      });
      if(response.ok) {
        alert("Deleted !")
      }
      onUpdate();
    }
  }

  const fieldsToShow = [
    { key: 'firstName', label: 'First Name', Component: Input },
    { key: 'lastName', label: 'Last Name', Component: Input },
    { key: 'email', label: 'Email', Component: Input },
    { key: 'url', label: 'Link', Component: Input },
    { key: 'title', label: 'Title', Component: Input },
    { key: 'location', label: 'Location', Component: Input },
    { key: 'freetext', label: 'Freetext', Component: InputArea },
    { key: 'skills', label: 'Skills', Component: InputArea },
  ];

  if(!item) {
    return <div></div>
  }

  return (
    <div className="flex">
      <div
        className={
          'flex flex-col gap-[5px] max-h-[80vh] overflow-y-auto w-full'
        }
      >
        <div className="flex justify-end gap-[10px] mb-[10px]">
          <div className="mr-auto">
            <MenuModerationStatus
              status={
                state.moderation_status === undefined
                  ? item.moderation_status
                  : state.moderation_status
              }
              onApprove={() =>
                dispatch({ type: 'SET', key: 'moderation_status', value: true })
              }
              onReject={() =>
                dispatch({
                  type: 'SET',
                  key: 'moderation_status',
                  value: false,
                })
              }
            />
          </div>
          {edited && (
            <div className="w-[100px]">
              <MenuItem
                id={'save'}
                key={'save'}
                Icon={saving ? TailSpin : SendFill}
                title={saving ? 'Saving' : 'Save'}
                setMenu={saveAction}
                menu={saving ? 'save' : ''}
              />
            </div>
          )}
          <MenuItem
            id={'delete'}
            key={'delete'}
            Icon={Trash}
            title={'Delete'}
            setMenu={onDelete}
          />
          <div className="w-[100px]">
            <MenuItem
              id={'print'}
              key={'print'}
              Icon={Printer}
              title={'Print'}
              setMenu={() => {window.open(`/api/print/?id=${selected}&type=candidate`)}}
            />
          </div>
        </div>
        {item.profilePicture && (
          <img
            className={'rounded-full h-[150px] w-[150px] m-auto'}
            src={`data:image/jpeg;base64,${item.profilePicture}`}
            alt="Profile"
          />
        )}
        {fieldsToShow.map((field) => (
          <field.Component
            key={`input-${field.key}-${selected}-${item.moderation_token}`}
            field={{
              id: field.key,
              label: field.label,
              value: item[field.key],
            }}
            onFieldEdit={updateValue}
            loading={false}
            errors={[]}
          />
        ))}
      </div>
    </div>
  );
};

const RenderItemJob = ({ selected, item, token, onUpdate }) => {
  const [itemCopy, setItemCopy] = useState({});
  const [state, dispatch] = useReducer(renderItemReducer, {});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    dispatch({ type: 'RESET' });
    setItemCopy(item);
  }, [item]);

  const updateValue = useCallback(
    (key, value) => {
      dispatch({ type: 'SET', key, value });
    },
    [dispatch]
  );

  const edited =
    Object.keys(state).filter((k) => itemCopy[k] !== state[k]).length > 0;

  const saveAction = async () => {
    setSaving(true);
    const response = await fetch(`${apiUrl}/api/jobs`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({ ...item, ...state, id: selected }),
    });

    if (response.ok) {
      await response.json();
      setSaving(false);
      setItemCopy({ ...item, ...state });
      onUpdate();
    } else {
      console.error('Cannot update');
    }
  };

  const onDelete = async () => {
    const confirmed = window.confirm("Are you sure you want to delete, this cannot be un-done ?")
    if(confirmed) {
      const response = await fetch(`${apiUrl}/api/jobs`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ ...item, ...state, id: selected, delete: true }),
      });
      if(response.ok) {
        alert("Deleted !")
      }
      onUpdate();
    }
  }

  const fieldsToShow = [
    { key: 'name', label: 'Company Name', Component: Input },
    { key: 'website', label: 'Company Website', Component: Input },
    { key: 'jobTitle', label: 'Job title', Component: Input },
    { key: 'jobDescription', label: 'Description', Component: InputArea },
    { key: 'jobOfferLink', label: 'Job link', Component: Input },
    { key: 'freetext', label: 'Freetext', Component: InputArea },
    { key: 'skills', label: 'Skills', Component: InputArea },
    { key: 'location', label: 'Location', Component: Input },
    { key: 'recruiterEmail', label: 'Recruiter Email', Component: Input },
    { key: 'recruiterName', label: 'Recruiter Name', Component: Input },
  ];

  if(!item) {
    return <div></div>
  }

  return (
    <div className="flex">
      <div
        className={
          'flex flex-col gap-[5px] max-h-[80vh] overflow-y-auto w-full'
        }
      >
        <div className="flex justify-end gap-[10px]">
          <div className="mr-auto">
            <MenuModerationStatus
              status={
                state.moderation_status === undefined
                  ? item.moderation_status
                  : state.moderation_status
              }
              onApprove={() =>
                dispatch({ type: 'SET', key: 'moderation_status', value: true })
              }
              onReject={() =>
                dispatch({
                  type: 'SET',
                  key: 'moderation_status',
                  value: false,
                })
              }
            />
          </div>
          {edited && (
            <div className="w-[100px]">
              <MenuItem
                id={'save'}
                key={'save'}
                Icon={saving ? TailSpin : SendFill}
                title={saving ? 'Saving' : 'Save'}
                setMenu={saveAction}
                menu={saving ? 'save' : ''}
              />
            </div>
          )}
          <MenuItem
            id={'delete'}
            key={'delete'}
            Icon={Trash}
            title={'Delete'}
            setMenu={onDelete}
          />
          <div className="w-[100px]">
            <MenuItem
              id={'print'}
              key={'print'}
              Icon={Printer}
              title={'Print'}
              setMenu={() => {window.open(`/api/print/?id=${selected}&type=job`)}}
            />
          </div>
        </div>
        {item.profilePicture && (
          <img
            className={'rounded-full h-[150px] w-[150px] m-auto'}
            src={`data:image/jpeg;base64,${item.profilePicture}`}
            alt="Profile"
          />
        )}
        {fieldsToShow.map((field) => (
          <field.Component
            key={`input-${field.key}-${selected}-${item.moderation_token}`}
            field={{
              id: field.key,
              label: field.label,
              value: item[field.key],
            }}
            onFieldEdit={updateValue}
            loading={false}
            errors={[]}
          />
        ))}
      </div>
    </div>
  );
};

const ListItems = ({ token, type, Render, RenderItem, onUpdate }) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);

  const fetchData = async () => {
    const response = await fetch(`${apiUrl}/api/${type}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    if (response.ok) {
      const jsonData = await response.json();
      setItems(jsonData.data);
    } else {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={'flex gap-[20px]'}>
      <div
        className={
          'w-1/5 flex flex-col gap-[10px] max-h-[80vh] overflow-y-auto'
        }
      >
        {Object.keys(items).map((key) => (
          <MenuItem
            key={key}
            Icon={null}
            title={<Render item={items[key]} />}
            setMenu={() => setSelected(key)}
            color={getModerationColor(items[key].moderation_status)}
            id={key}
            menu={selected}
            count={
              <ModerationBadgeStatus status={items[key].moderation_status} />
            }
          />
        ))}
      </div>
      {selected && (
        <div className={'w-4/5 relative'}>
          <RenderItem
            id={`selected-${selected}`}
            token={token}
            selected={selected}
            item={items[selected]}
            onUpdate={() => {
              onUpdate();
              fetchData();
            }}
          />
        </div>
      )}
    </div>
  );
};

const Moderation = ({ token }) => {
  const [menu, setMenu] = useState(null);
  const [moderation, setModeration] = useState(null);
  const [firewall, setFirewall] = useState(null);

  const fetchModeration = async () => {
    const response = await fetch(`${apiUrl}/api/moderation`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    if (response.ok) {
      const jsonData = await response.json();
      setModeration(jsonData.data);
      setFirewall(true);
    } else {
      if (response.status === 401 || response.status === 403) {
        setFirewall(false);
      }
    }
  };

  useEffect(() => {
    fetchModeration();
  }, []);

  if (!firewall) {
    return (
      <div className="flex flex-col gap-[30px] p-[1.5em] justify-start items-center h-full w-full min-h-[100vh] bg-[#01222C]">
        <img
          className={'h-[70px]'}
          src={'cf-logo.png'}
          alt={'Cloudfest Logo'}
        />
        {firewall === null ? (
          <BallTriangle />
        ) : (
          <div className="bg-white w-[500px] h-[50px] flex items-center justify-center rounded-[10px] p-[20px]">
            Your token is invalid, please contact a system administrator
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[30px] p-[1.5em] justify-start items-center h-full w-full min-h-[100vh] bg-[#01222C]">
      <img className={'h-[70px]'} src={'cf-logo.png'} alt={'Cloudfest Logo'} />
      <div className={'bg-white w-full min-h-[85vh] rounded-[10px] p-[20px]'}>
        <div className="flex gap-[20px] grid-cols-2	invisible lg:visible">
          <ModerationMenu
            setMenu={setMenu}
            menu={menu}
            moderation={moderation}
          />
          <div className={'w-full'}>
            {menu === 'jobs' && (
              <ListItems
                onUpdate={fetchModeration}
                token={token}
                Render={RenderJobCard}
                type={menu}
                RenderItem={RenderItemJob}
              />
            )}
            {menu === 'candidates' && (
              <ListItems
                onUpdate={fetchModeration}
                token={token}
                Render={RenderCandidateCard}
                type={menu}
                RenderItem={RenderItemCandidate}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Moderation;
