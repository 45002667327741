import TextBox from './text-box';
import FormBuilder from './form-builder';
import { useState } from 'react';
import apiUrl from './api';

const EmployerProfile = ({ onCompleted }) => {
  const [data, setData] = useState();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const onFieldUpdate = (key, val) => {
    setData({ ...data, [key]: val });
  };

  const submitForm = async () => {
    setLoading(true);
    setErrors([]);
    const response = await fetch(`${apiUrl}/api/job`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    setLoading(false);
    if (response.ok) {
      const resp = await response.json();
      onCompleted(resp.requestId);
    } else {
      if (response.status === 400) {
        const resp = await response.json();
        setErrors(resp.data);
      }
    }
  };
  return (
    <div className={'flex flex-col gap-[15px]'}>
      <TextBox>
        <div>You can fill the details of your job opportunity below</div>
        <div>Your information can be shared on the Job Board</div>
      </TextBox>
      <FormBuilder
        loading={loading}
        onFieldEdit={onFieldUpdate}
        errors={errors}
        fields={[
          {
            type: 'picture',
            label:
              'You can add your company logo / picture to help hackathon participants recognize you',
            required: false,
            id: 'profilePicture',
          },
          {
            type: 'text',
            label: 'Company name',
            required: true,
            id: 'name',
          },
          {
            type: 'text',
            label: 'Company website',
            required: true,
            id: 'website',
          },
          { type: 'text', label: 'Job title', required: true, id: 'jobTitle' },
          {
            type: 'textarea',
            label: 'Job description',
            required: true,
            id: 'jobDescription',
            maxLen: 630,
            rows: 8,
          },
          {
            type: 'text',
            label: 'Link to the job offer',
            required: true,
            id: 'jobOfferLink',
          },
          { type: 'text', label: 'Location', required: true, id: 'location' },
          {
            type: 'textarea',
            label: 'Skills/Languages required',
            required: true,
            id: 'skills',
          },

          {
            type: 'text',
            label: 'Recruiter Name',
            required: true,
            id: 'recruiterName',
          },
          {
            type: 'email',
            label: 'Recruiter Email',
            required: true,
            id: 'recruiterEmail',
          },
          {
            type: 'textarea',
            label: 'You can add here a comment to your job offer',
            required: true,
            id: 'freetext',
            maxLen: 630,
            rows: 4,
          },
          { type: 'submit', label: 'Submit your profile', onClick: submitForm },
        ]}
      />
    </div>
  );
};

const CandidateProfile = ({ onCompleted }) => {
  const [data, setData] = useState();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const onFieldUpdate = (key, val) => {
    setData({ ...data, [key]: val });
  };

  const submitForm = async () => {
    setLoading(true);
    setErrors([]);
    const response = await fetch(`${apiUrl}/api/candidate`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data),
    });
    setLoading(false);
    if (response.ok) {
      const resp = await response.json();
      onCompleted(resp.requestId);
    } else {
      if (response.status === 400) {
        const resp = await response.json();
        setErrors(resp.data);
      }
    }
  };

  return (
    <div className={'flex flex-col gap-[15px]'}>
      <TextBox>
        <div>You can fill your profile in the form below</div>
        <div>Your information can be shared on the Job Board</div>
      </TextBox>
      <FormBuilder
        onFieldEdit={onFieldUpdate}
        errors={errors}
        loading={loading}
        fields={[
          {
            type: 'picture',
            label:
              'You can add a profile picture to help hackathon participants recognize you',
            required: false,
            id: 'profilePicture',
          },
          {
            type: 'text',
            label: 'First Name',
            required: true,
            id: 'firstName',
          },
          {
            type: 'text',
            label: 'Last Name',
            required: true,
            id: 'lastName',
          },
          { type: 'email', label: 'Email', required: true, id: 'email' },
          { type: 'text', label: 'Current Job Title / Occupation', required: true, id: 'title' },
          { type: 'text', label: 'Location', required: true, id: 'location' },
          { type: 'textarea', label: 'Skills / Languages', required: true, id: 'skills',maxLen: 630,
          rows: 8,},
          {
            type: 'text',
            label: 'LinkedIn / XING profile or Website URL',
            required: true,
            id: 'url',
          },
          {
            type: 'textarea',
            label: 'You can add here a description / comment to your profile',
            required: true,
            id: 'freetext',
            maxLen: 630,
            rows: 8,
          },
          { type: 'submit', label: 'Submit your profile', onClick: submitForm },
        ]}
      />
    </div>
  );
};

const Profile = ({ profile, onCompleted }) => {
  return (
    <div>
      {profile === 'candidate' ? (
        <CandidateProfile onCompleted={onCompleted} />
      ) : (
        <EmployerProfile onCompleted={onCompleted} />
      )}
    </div>
  );
};

export default Profile;
