import { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import TextBox from './text-box';
import {
  CheckCircle,
  Compass,
  Megaphone,
  RocketTakeoff,
  Search,
  Pencil,
} from 'react-bootstrap-icons';
import BackdropAlert from './backdrop-alert';
import BigButton from './big-button';
import DangerButton from './danger-button';

import AvatarImageCropper from 'react-avatar-image-cropper';
import { v4 as uuidv4 } from 'uuid';
import apiUrl from './api';

const FieldHolder = ({ children, field, errors = [] }) => {
  const fieldErrors = errors.filter((error) => error.field === field.id);

  return (
    <div>
      {field.label && (
        <label className={'text-[12px] my-[3px]'} for={field.id}>{field.label}</label>
      )}
      <div>{children}</div>
      {fieldErrors.map((err) => (
        <div
          key={`${err.field}-error`}
          className={'text-[10px] text-[#FF0000]'}
        >
          {err.errorString}
        </div>
      ))}
    </div>
  );
};

const Input = ({ field, onFieldEdit, loading, errors }) => {
  const [value, setValue] = useState(field.value);

  useEffect(() => {
    const id = setTimeout(() => onFieldEdit(field.id, value), 400);
    return () => {
      clearTimeout(id);
    };
  }, [value, field.id]);

  return (
    <FieldHolder field={field} errors={errors}>
      <input
        id={field.id}
        disabled={loading}
        type={field.type}
        className={
          'border rounded-[6px] w-full p-[5px] outline-[#07f2f2] text-[14px]'
        }
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
    </FieldHolder>
  );
};

const InputArea = ({ field, onFieldEdit, loading, errors }) => {
  const [value, setValue] = useState(field.value || '');
  useEffect(() => {
    const id = setTimeout(() => onFieldEdit(field.id, value), 400);
    return () => {
      clearTimeout(id);
    };
  }, [value]);

  const rows = value.split('\n');
  const nbRows = rows.filter((row) => row.length > 60).length + rows.length;

  return (
    <FieldHolder field={field} errors={errors}>
      <textarea
        id={field.id}
        disabled={loading}
        className={
          'border rounded-[6px] w-full p-[5px] outline-[#07f2f2] text-[14px]'
        }
        rows={field.rows}
        maxLength={field.maxLen}
        onChange={(e) => setValue(e.target.value)}
        defaultValue={value}
      ></textarea>
      {nbRows > field.rows && (
        <div className="text-[10px] text-right text-[#FF0000]">
          Warning : We cannot print more than {field.rows} rows, your text is
          going to be truncated on printing
        </div>
      )}
      {(field.maxLen ||
        field.rows) && (
          <div className={'text-[10px] text-right'}>
            Length : {value.length}/{field.maxLen} - Rows : {nbRows}/
            {field.rows}
          </div>
        )}
    </FieldHolder>
  );
};

const Skill = ({
  skill,
  onChange,
  onDelete,
  options,
  onSave,
  onCreate,
  getSkillName,
}) => {
  return (
    <div className={'flex flex-col p-[10px] gap-[4px]'}>
      <div className={'w-full flex flex-col gap-[15px]'}>
        <>
          <TextBox>
            <div>
              You will find a list of various skills in the list below, please
              select one or create your own
            </div>
          </TextBox>
          {skill.value ? (
            <div
              onClick={() => onChange({ ...skill, value: null })}
              className="flex drop-shadow-md cursor-pointer border rounded-[12px] py-[10px] px-[15px] justify-between items-center"
            >
              <div>{getSkillName(skill.value)}</div>
              <div>
                <Pencil />
              </div>
            </div>
          ) : (
            <CreatableSelect
              isClearable
              options={options}
              onChange={({ value }) => onChange({ ...skill, value })}
              onCreateOption={(opt) => onCreate(skill.id, opt)}
            />
          )}
        </>
        {skill.value && (
          <>
            <TextBox>
              <div>Could you please specify a level for that skill ?</div>
            </TextBox>
            <div className={'flex flex-col gap-[8px]'}>
              {[
                {
                  value: 'irrelevant',
                  name: 'Irrelevant / Not applicable',
                  Icon: Megaphone,
                },
                { value: 'beginner', name: 'Beginner', Icon: Compass },
                { value: 'intermediate', name: 'Intermediate', Icon: Search },
                { value: 'advanced', name: 'Advanced', Icon: CheckCircle },
                { value: 'expert', name: 'Expert', Icon: RocketTakeoff },
              ].map(({ value, name, Icon }) => (
                <div
                  onClick={() => onChange({ ...skill, level: value })}
                  className={`flex ${
                    skill.level === value
                      ? 'bg-[#04222c] text-white'
                      : 'bg-[#f4f4f4] hover:bg-[#e9e9e9]'
                  } drop-shadow-md cursor-pointer border rounded-[12px] py-[10px] px-[15px] justify-between items-center`}
                >
                  {name}{' '}
                  <Icon fill={skill.level === value ? '#FFFFFF' : '#818181'} />
                </div>
              ))}
            </div>
          </>
        )}
        {skill.edition ? (
          <BigButton
            title={'Add this skill'}
            onClick={() => {
              onChange({ ...skill, edition: false });
              onSave();
            }}
          />
        ) : (
          <div class="flex gap-[10px] flex-col">
            <BigButton
              title={'Update this skill'}
              onClick={() => {
                onChange({ ...skill, edition: false });
                onSave();
              }}
            />
            <DangerButton
              title={'Delete this skill'}
              onClick={() => onDelete(skill.id)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const SkillsSelector = ({
  field,
  onFieldEdit,
  loading,
  errors,
  viewOnly = false,
}) => {
  const fieldErrors = errors.filter((error) => error.field === field.id);
  const [skills, setSkills] = useState(field.value || []);
  const [editSkill, setEditSkill] = useState(null);
  const [options, setOptions] = useState([]);
  const getSkillName = (id) => {
    const sk = options.find((opt) => opt.value === id);
    return sk ? sk.label : null;
  };
  const addSkill = () => {
    const id = `skill-${skills.length}`;
    setSkills([...skills, { id, value: null, level: null, edition: true }]);
    setEditSkill({ id, existing: false });
  };

  const onChangeSkill = (data) => {
    let newSkillSet = [...skills];
    const index = newSkillSet.findIndex((skill) => skill.id === data.id);
    newSkillSet[index] = data;
    setSkills(newSkillSet);
  };
  const onDelete = (id) => {
    const newSkillSet = [...skills].filter((skill) => skill.id !== id);
    setEditSkill(null);
    setSkills(newSkillSet);
  };
  const onCreate = (id, option) => {
    const uuid = uuidv4();
    // We create the option
    setOptions([...options, { label: option, value: uuid }]);

    // We update the array
    let tmp = [...skills];
    const index = tmp.findIndex((skill) => skill.id === id);
    tmp[index]['value'] = uuid;
    setSkills(tmp);
  };

  useEffect(() => {
    onFieldEdit(field.id, skills);
  }, [skills]);

  useEffect(() => {
    const fetchSkills = async () => {
      const response = await fetch(`${apiUrl}/api/skills`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      });
      if (response.ok) {
        const data = await response.json();
        setOptions(data.skills);
      }
    };

    fetchSkills();
  }, []);

  return (
    <FieldHolder field={field}>
      <div className={'flex flex-col gap-[5px]'}>
        {!!editSkill && (
          <BackdropAlert>
            <Skill
              skill={skills.find((skill) => skill.id === editSkill.id)}
              options={options}
              onChange={onChangeSkill}
              onSave={() => setEditSkill(null)}
              onDelete={onDelete}
              onCreate={onCreate}
              getSkillName={getSkillName}
            />
          </BackdropAlert>
        )}
        {skills.map((skill) => (
          <div
            className={
              'border py-[10px] text-center cursor-pointer rounded-[6px] text-[12px]'
            }
            onClick={() => setEditSkill({ id: skill.id, existing: true })}
          >
            {getSkillName(skill.value)}
          </div>
        ))}
        {!viewOnly && (
          <BigButton
            title={'Add a new skill / language'}
            disabled={loading}
            onClick={addSkill}
          />
        )}
        {fieldErrors.map((err) => (
          <div
            key={`${err.field}-error`}
            className={'text-[10px] text-[#FF0000]'}
          >
            {err.errorString}
          </div>
        ))}
      </div>
    </FieldHolder>
  );
};

const PictureSelector = ({ setImage }) => {
  var blobToBase64 = function (blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataUrl = reader.result;
      var base64 = dataUrl.split(',')[1];
      callback(base64);
    };
    reader.readAsDataURL(blob);
  };

  const onPictureSelected = (blob) => {
    const picture = URL.createObjectURL(blob);
    blobToBase64(blob, (base64data) => {
      setImage({
        url: picture,
        data: base64data,
      });
    });
  };

  return (
    <div
      style={{ width: '220px', height: '220px', margin: 'auto' }}
      className="rounded-full"
    >
      <AvatarImageCropper apply={onPictureSelected} isBack={true} />
    </div>
  );
};

const PictureField = ({ field, onFieldEdit, loading }) => {
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (image) {
      onFieldEdit(field.id, image.data);
    }
  }, [image, field.id]);

  if (image) {
    return (
      <div className="flex justify-center items-center flex-col gap-[15px]">
        <div className="rounded-full overflow-hidden">
          <img src={image.url} alt="profile" />
        </div>
        <div
          className="cursor-pointer text-[10px] flex gap-[5px] items-center"
          onClick={() => !loading && setImage(null)}
        >
          <Pencil /> Change my picture
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center items-center flex-col gap-[15px]">
      <PictureSelector setImage={setImage} />
      <div className="text-[10px] text-center">{field.label}</div>
    </div>
  );
};

const GDPRSubmit = ({title, onClick, loading}) => {
  const [gdpr, setGdpr] = useState(false);
  const openGDPR = () => {
    document.getElementById("gdpr-link").click();
  }

  return <div>
    <div className="p-[10px] rounded-[6px] flex gap-[10px] items-center bg-[#023470] text-white">
      <img src="gdpr.jpeg" className="w-[40px] h-[40px] rounded-full"/>
      <div class="flex flex-col text-[12px]">
        <div>- Your informations will be used to print and publish your offer or profile.</div>
        <div>- We will never sell or transfer informations to any third party.</div>
        <div>- Your data are saved and will not be shared outside the European Union.</div>
        <div onClick={openGDPR} className="hover:underline cursor-pointer">- You can view our full Privacy Policy by clicking here.</div>
        <div>- Your data will be deleted from this platform on <span class="font-[500]">March 27th 2023.</span> or anytime before that at your request.</div>
      </div>
    </div>
    <div className="text-[12px] p-[5px] my-[10px]">
      <div class="flex gap-[10px]">
        <input className="min-w-[20px]"type="checkbox" onClick={(e) => setGdpr(e.target.checked)}/>
        <div>I have read, I understand and I agree to the <span onClick={openGDPR} className="hover:underline cursor-pointer">privacy policy</span> and the collection of my data for the purpose of the <span class="font-[500]">Cloudfest Hackathon Job Board.</span></div>
      </div>
    </div>
    <BigButton
      disabled={!gdpr}
      title={title}
      onClick={onClick}
      key={'submit'}
      loading={true}
    />
  </div>
}

const FormBuilder = ({ fields, onFieldEdit, loading, errors = [] }) => {
  return (
    <div className={`flex flex-col gap-[10px]`}>
      {fields.map((field) => {
        switch (field.type) {
          case 'text':
          case 'email':
            return (
              <Input
                field={field}
                key={field.id}
                onFieldEdit={onFieldEdit}
                loading={loading}
                errors={errors}
              />
            );
          case 'skills':
            return (
              <SkillsSelector
                field={field}
                key={field.id}
                onFieldEdit={onFieldEdit}
                loading={loading}
                errors={errors}
              />
            );
          case 'picture':
            return (
              <PictureField
                field={field}
                key={field.id}
                onFieldEdit={onFieldEdit}
                loading={loading}
              />
            );
          case 'textarea':
            return (
              <InputArea
                field={field}
                key={field.id}
                onFieldEdit={onFieldEdit}
                loading={loading}
                errors={errors}
              />
            );
          case 'submit':
            return (
              <GDPRSubmit
                title={field.label}
                onClick={field.onClick}
                key={'submit'}
                loading={loading}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export { FormBuilder as default, Input, InputArea, SkillsSelector };
